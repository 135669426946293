<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <h1>Error 404</h1>
      <p>Page not found</p>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //this.$alertError(this.$t('pages/error-not-fount/alert/linkExpiredMessage'));
  },
};
</script>

<style lang="scss" scoped></style>
